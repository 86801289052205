<template>
  <div>
    <h2>{{ $t("pages.user_group") }}</h2>
    <user-group-form :user-group="userGroup" payload-process="create" />
  </div>
</template>

<script>
import userGroupForm from "../components/baseGroups/UserGroupForm";

export default {
  name: "UserGroupCreate",
  components: {
    userGroupForm
  },
  data() {
    return {
      names: [],
      loading: false,
      createLoading: false,
      groupsLoading: false,
      filteredUsers: [],
      userGroup: {
        name: "",
        description: "",
        sources_groups_ids: [],
        users_ids: []
      }
    };
  },
  methods: {
    create() {
      this.createLoading = true;
      this.$store.dispatch('access_groups/createUserGroup', this.userGroup).then(() => {
        this.createLoading = false;
        this.$router.push('/settings/user-groups');
      })
    },
    getUsers() {
      this.loading = true;
      this.$store.dispatch('users/getUsersList', {order_type: 'ASC', order_by: 'login'})
        .then(() => {
          this.loading = false;
          this.filteredUsers = this.users;
        })
    },
    loadGroups(){
      this.groupsLoading = true;
      this.$store.dispatch('source_groups/getGroupList', {}).then(() => {
        this.groupsLoading = false
      })
    },
    getFilteredTags(text) {
      this.filteredUsers = this.users.filter((option) => {
        return option.login
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
  },
  watch: {
  },
  mounted() {
    this.getUsers();
    this.loadGroups()
  }
}
</script>

<style scoped>
  h2 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 700;
  }
  h3 {
    font-weight: 700;
    margin-bottom: 10px;
  }
</style>